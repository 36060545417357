<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <ayl-table :table="table">
        <div slot="ctrl-button">
            <el-button type="warning" @click="$router.push('/vehicle-management/addSIM')">新增</el-button>
        </div>
        </ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        nav: [
          {name: "车辆管理", path: ""},
          {name: "SIM卡管理", path: ""}],
        dialogExport: false,
        checkList: [],
        checkIndex: [],
        btnLoading: false,

        fileKey: null,
        importLoading: false,
        dialogImport: false,
        fileName: null,

        table: {
          // api 查询当前列表页数据的全局方法
          api: vm.$api.SimInfoByQueryCondition,
          // query 查询当前列表页数据的参数
          query: {
            simStatus: null,
            serviceProvider: null,
            expireDateStart: null,
            expireDateEnd: null,
            queryContent: null,
          },
          searchData: [{
            type: 'select',
            title: '运营商',
            model: 'serviceProvider',
            placeholder: '请选择',
            option: this.$enums.ServiceProvider.listDes
          }, {
            type: 'dateSection',
            title: '到期时间',
            date: [],
            modelName: ['expireDateStart', 'expireDateEnd'],
          }, {
            type: 'input',
            model: 'queryContent',
            placeholder: '车牌号/SIM卡号'
          }],
          columns: [
            {
              title: 'SIM卡号',
              key: 'simNumber',
              showTooltip: true,
            }, {
              title: '运营商',
              key: 'serviceProviderDes',
              width: '100px',
            }, {
              title: '套餐',
              key: 'pricePackage',
              width: '100px',
            }, {
              title: '启用状态',
              key: 'status',
              width: '100px',
            }, {
              title: '车牌号',
              key: 'plateNumber',
              width: '100px',
            }, {
              title: '设备号',
              key: 'deviceId',
              showTooltip: true,
            }, {
              title: '到期时间',
              key: 'expireDate',
              filter: 'str2ymd',
              showTooltip: true,
            }, {
              title: '所属公司',
              key: 'company',
              showTooltip: true,
            }, {
              title: '操作',
              width: '120px',
              render (h, ctx) {
                const date = h('span', {
                  class: {
                    'table-delete': true
                  },
                  on: {
                    click: vm.delete.bind(this, ctx.row)
                  }
                }, '删除')
                return h('span', [
                  h('span', {
                    class:{
                      'table-view' : true
                    },
                    on: {
                      click: vm.goEdit.bind(this, ctx.row)
                    }
                  }, '编辑'), ctx.row.status==='已启用' ? date : date
                ])
              }
            }]
        }
      }
    },
    methods:{
      goEdit(value){
        this.$router.push({
          path: '/vehicle-management/addSIM',
          query: {
            simNumber: value.simNumber,
            type: 'edit',
            status: value.status,
          }
        })
      },
      async delete(val){
        await this.$confirm(`您确认删除【${val.simNumber}】吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.$api.DeleteSimInfo({
          simNumbers: [val.simNumber]
        })
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        });
        await this.$search(this.table)
      }
    },
    created() {
      if (this.$route.query.option) {
        this.table.searchData[0].mode = ['即将到期', '到期']
      }
    },
    beforeRouteEnter (to, from, next) {
      if(to.name=="Sim-management"){
        next(async vm=>{
          await vm.$search(vm.table)
        });
      }else{  
        next();
      }
    },
  }
</script>

<style lang='sass' scoped>
  /deep/ .el-checkbox
    min-width: 90px
</style>
